import { Provider } from "@shopify/app-bridge-react";
import { ReactNode, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { configForAppBridgeProvider } from "../lib/configForAppBridgeProvider";

interface Props {
  children: ReactNode;
}

export function AppBridgeProvider({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const history = useMemo(
    () => ({ replace: (path: string) => navigate(path, { replace: true }) }),
    [navigate],
  );

  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history],
  );

  return (
    <Provider config={configForAppBridgeProvider} router={router}>
      {children}
    </Provider>
  );
}
