// export function colorDecode(color: [number, number, number, number]) {
//   return {
//     hue: color[0],
//     saturation: color[1],
//     brightness: color[2],
//     alpha: color[3],
//   };
// }

// export function colorEncode(color: {
//   hue: number;
//   saturation: number;
//   brightness: number;
//   alpha: number;
// }) {
//   return [color.hue, color.saturation, color.brightness, color.alpha];
// }

export function colorEncode([r, g, b]: [number, number, number]) {
  return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
}

export function colorDecode(hex: string): [number, number, number, number] {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b, 1];
}
