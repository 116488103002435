import { useMutation } from "@tanstack/react-query";
import { customFetch } from "../lib/customFetch";
import { buildUrl } from "../lib/buildUrl";
import { useAppBridge } from "@shopify/app-bridge-react";

export function useUpdateFloatingWidget() {
  const app = useAppBridge();

  const mutation = useMutation({
    mutationFn: (floatingWidgetData: Record<string, any>) => {
      return customFetch(buildUrl("/floating-widget"), {
        method: "PUT",
        app,
        body: { input: floatingWidgetData },
      });
    },
  });

  return mutation;
}
