import { useMutation } from "@tanstack/react-query";
import { customFetch } from "../lib/customFetch";
import { buildUrl } from "../lib/buildUrl";
import { useAppBridge } from "@shopify/app-bridge-react";

export function useUpdateContact(contactId: string) {
  const app = useAppBridge();

  const mutation = useMutation({
    mutationFn: (data: Record<string, any>) => {
      return customFetch(buildUrl(`/contacts/${contactId}`), {
        method: "PUT",
        app,
        body: { input: data },
      });
    },
  });

  return mutation;
}
