import { useAppBridge } from "@shopify/app-bridge-react";
import { useQuery } from "@tanstack/react-query";
import { customFetch } from "../lib/customFetch";
import { buildUrl } from "../lib/buildUrl";

export function useListContacts() {
  const app = useAppBridge();

  const payload = useQuery({
    queryKey: ["contacts"],
    queryFn: async () => {
      return customFetch(buildUrl("/contacts"), { app });
    },
  });

  return payload;
}
