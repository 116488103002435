import { useListContacts } from "../api/useListContacts";
import { ContactForm } from "../components/ContactForm";

export function Contacts() {
  const { data, isLoading, isError } = useListContacts();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Something went wrong</p>;
  }

  return <ContactForm data={data.data[0]} />;
}
