import { Routing } from "./components/Routing";
import { ShopifyPolarisProvider } from "./components/ShopifyPolarisProvider";

export function App() {
  return (
    <ShopifyPolarisProvider>
      <Routing />
    </ShopifyPolarisProvider>
  );
}
