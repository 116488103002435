import { Button, List } from "@shopify/polaris";
import { useCreateScript } from "../api/useCreateScript";
import { useDeleteScript } from "../api/useDeleteScript";
import { useGetStore } from "../api/useGetStore";
import { Modal } from "@shopify/polaris";
import { useState } from "react";
import { trackEvent } from "../lib/mixpanel";

export function AppEnableDisableButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { data, error, isLoading, refetch } = useGetStore();

  // const { mutateAsync: createFn, isLoading: creating } = useCreateScript();
  // const { mutateAsync: deleteFn, isLoading: deleting } = useDeleteScript();

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>something went wrong</p>;
  // }

  // const isAppEnabled = data.data.isAppEnabled;

  return (
    <>
      <Modal
        onClose={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        title="Add widget on Storefront"
      >
        <Modal.Section>
          <List>
            <List.Item>Go to Online Store &gt; Themes</List.Item>
            <List.Item>Click on Customize Theme</List.Item>
            <List.Item>
              In the Apps Section at the end, Click Add block
            </List.Item>
            <List.Item>
              Add the Block with name Whatsapp Customer Support App
            </List.Item>
            <List.Item>Click Save</List.Item>
          </List>
        </Modal.Section>
      </Modal>
      <Button
        onClick={async () => {
          setIsModalOpen(true);
          trackEvent("Add Widget to Storefront Clicked");
        }}
        primary={true}
      >
        Add widget on storefront
      </Button>
    </>
  );
}
