import { createBrowserRouter } from "react-router-dom";
import { AppLayout } from "../components/AppLayout";
import { Dashboard } from "../pages/Dashboard";
import { WidgetSettings } from "../pages/WidgetSettings";
import { Analytics } from "../pages/Analytics";
import { RootPage } from "../pages/Root";
import { Contacts } from "../pages/Contacts";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage to="/app/settings/widget" />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/app",
        element: <Dashboard />,
      },
      {
        path: "/app/settings/widget",
        element: <WidgetSettings />,
      },
      {
        path: "/app/contacts",
        element: <Contacts />,
      },
      {
        path: "/app/analytics",
        element: <Analytics />,
      },
    ],
  },
]);
