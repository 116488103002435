import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export function ShopifyPolarisProvider({ children }: Props) {
  return <AppProvider i18n={enTranslations}>{children}</AppProvider>;
}
