let shopName: string;

export function getShopName() {
  if (shopName) {
    return shopName;
  }

  const qs = new URLSearchParams(window.location.search);
  const shop = qs.get("shop");

  return shop as string;
}
