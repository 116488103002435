import { useState } from "react";
import { useUpdateContact } from "../api/useUpdateContact";
import { AppEnableDisableButton } from "./AppEnableDisableButton";
import styles from "./ContactForm.module.scss";
import { Button, TextField, Toast } from "@shopify/polaris";
import { trackEvent } from "../lib/mixpanel";

export function ContactForm({ data }: { data: Record<string, any> }) {
  const [formState, setFormState] = useState(data);
  const [isSuccessToastActive, setIsSuccessToastActive] = useState(false);
  const { isLoading, mutateAsync } = useUpdateContact(data.id);

  return (
    <main className={styles.page}>
      <div className={styles.header}>
        <h1 className={styles.pageTitle}>Contact</h1>
        <AppEnableDisableButton />
      </div>
      <div className={styles.layout}>
        <section className={styles.settingsSection}>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await mutateAsync(formState);
              trackEvent("Contact Settings Updated", formState);
              setIsSuccessToastActive(true);
            }}
            className={styles.formWrapper}
          >
            <TextField
              autoComplete=""
              label="Phone Number"
              value={formState.phoneNumber}
              helpText="Include your country code in the number and do not write any dash and spaces."
              onChange={(val) => {
                setFormState({ ...formState, phoneNumber: val });
              }}
            />

            <TextField
              multiline={3}
              autoComplete=""
              label="Prefilled Text"
              value={formState.prefilledMessage}
              onChange={(val) => {
                setFormState({ ...formState, prefilledMessage: val });
              }}
            />

            <Button submit loading={isLoading} primary>
              Save Changes
            </Button>
            {isSuccessToastActive && (
              <Toast
                content="Settings Saved Successfully. Don't forget to Enable the app"
                onDismiss={() => setIsSuccessToastActive(false)}
                duration={5000}
              />
            )}
          </form>
        </section>
      </div>
    </main>
  );
}
