import { useGetFloatingWidget } from "../api/useGetWidget";
import { WidgetSettingsForm } from "../components/WidgetSettingsForm";

export function WidgetSettings() {
  const { data, isLoading, isError } = useGetFloatingWidget();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Something went wrong</p>;
  }

  return <WidgetSettingsForm data={data.data} />;
}
