import { Outlet } from "react-router-dom";
import { AppBridgeProvider } from "./AppBridgeProvider";
import { QueryProvider } from "./QueryProvider";
import { Frame } from "@shopify/polaris";
import { getShopName } from "../lib/getShopName";
import { useEffect, useState } from "react";
import { buildUrl } from "../lib/buildUrl";
import { getHost } from "../lib/getHost";
import { isInsideIFrame } from "../lib/isInsideIframe";
import { createApp } from "@shopify/app-bridge";
import { configForAppBridgeProvider } from "../lib/configForAppBridgeProvider";
import { Redirect } from "@shopify/app-bridge/actions";
import { identifyUser } from "../lib/mixpanel";

export function AppLayout() {
  const shopName = getShopName();
  const host = getHost();

  const [status, setStatus] = useState({ loading: true, error: "" });

  useEffect(() => {
    if (!shopName) return;

    fetch(buildUrl(`/stores/${shopName}/check-oauth`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resp) => {
        console.log({ resp });
        if (resp.statusCode === 200 && resp.data.shopName) {
          identifyUser(resp.data.id, {
            shopName: resp.data.shopName,
            name: resp.data.name,
            email: resp.data.email,
          });
          // party
          setStatus({ loading: false, error: "" });
        } else if (resp.statusCode === 400) {
          const authUrl =
            `${process.env.REACT_APP_OAUTH_URL}?shop=${shopName}&host=${host}` as string;
          if (isInsideIFrame) {
            const appBridgeInstance = createApp(configForAppBridgeProvider);
            const redirect = Redirect.create(appBridgeInstance);
            redirect.dispatch(Redirect.Action.REMOTE, authUrl);
          } else {
            // redirect to auth
            window.location.href = authUrl;
          }
        } else if (resp.error) {
          setStatus({ loading: false, error: resp.error });
          // show error
        }
      });
  }, []);

  if (status.loading) {
    return <p>Loading...</p>;
  }

  if (status.error) {
    return <p>{status.error}</p>;
  }

  return (
    <AppBridgeProvider>
      <QueryProvider>
        <Frame>
          <Outlet />
        </Frame>
      </QueryProvider>
    </AppBridgeProvider>
  );
}
