import { ClientApplication } from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";

interface Options {
  body?: Record<string, any>;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  app: ClientApplication;
}

export async function customFetch(uri: string, options: Options) {
  const sessionToken = await getSessionToken(options.app);
  let method = "GET";
  let body;
  let headers: Record<string, any> = {
    Authorization: `Bearer ${sessionToken}`,
  };

  if (options.method) {
    method = options.method;
  }

  if (options.body) {
    body = JSON.stringify(options.body);
    headers = { ...headers, "Content-Type": "application/json" };
  }
  const stream = await fetch(uri, { method, body, headers });
  const response = await stream.json();

  return response;
}
