import mixpanel from "mixpanel-browser";

// init mixpanel
export function initMixpanel() {
  if (process.env.NODE_ENV === "production") {
    // @ts-ignore
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: true,
      disable_persistence: true,
    });
  }
}

// track event with mixpanel
export function trackEvent(event: string, data?: any) {
  if (process.env.NODE_ENV === "production") {
    try {
      mixpanel.track(event, data);
    } catch (e) {
      console.error(e);
    }
  }
}

// identify user
export function identifyUser(
  userId: string,
  { name, email, shopName }: { name: string; email: string; shopName: string }
) {
  if (process.env.NODE_ENV === "production") {
    try {
      mixpanel.identify(userId);
      // set details of user
      mixpanel.people.set({
        $name: name,
        $email: email,
        shopName,
      });
    } catch (e) {
      console.error(e);
    }
  }
}
