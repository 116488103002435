import { RangeSlider as ShopifyRangeSlider } from "@shopify/polaris";

interface RangeSliderProps {
  value: number;
  label: string;
  onChange: (value: number) => void;
  suffix: string;
}

export function RangeInput({
  value,
  label,
  suffix,
  onChange,
}: RangeSliderProps) {
  return (
    <ShopifyRangeSlider
      suffix={suffix}
      output={true}
      label={label}
      value={value}
      onChange={onChange}
    />
  );
}
