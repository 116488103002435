import { colorDecode, colorEncode } from "../lib/colorEncoders";

interface ColorPickerProps {
  value: [number, number, number];
  label: string;
  onChange: (value: [number, number, number, number]) => void;
}

export function ColorPicker({ value, onChange, label }: ColorPickerProps) {
  return (
    <label style={{ display: "flex", gap: "12px", alignItems: "center" }}>
      <input
        type="color"
        onChange={(e) => {
          const value = e.target.value;
          onChange(colorDecode(value));
        }}
        value={colorEncode(value)}
      />
      {label}
    </label>
  );
}
