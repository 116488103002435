import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function RootPage({ to }: { to: string }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({
      pathname: to,
      search: window.location.search,
    });
  });

  return <>Redirecting...</>;
}
